import React, { useState } from 'react'
import Layout, { Section } from '../widget/layout'
import { Banner, Grid, Modal } from '../ui'
import bannerBgSrc from '../../assets/images/banner-home.jpg'
import { connect } from 'react-redux'
import UserProfileCard, { ProfileImage } from '../ui/UserProfileCard'
import { graphql } from 'gatsby'
import { renderMdx } from '../helper/mdx'
import { ContentBock } from '../ui/typography'

export const Home = ({ data }) => {

  const {
    allContentYaml: { nodes: yamlNodes },
    allContentMember: { nodes: allMemberNodes },
  } = data

  const { members_page_members_list, fields: { contentMdx } } = yamlNodes[0]

  const [profileDetails, showProfileDetails] = useState(false)

  const membersList = members_page_members_list
    .map((name) => allMemberNodes.find(member => member.name === name))
    .filter(Boolean)

  const renderMemberProfilePreview = (userDetails) => {
    console.log(userDetails)
    const {profileImg, name, designation, shortDesc, fields } = userDetails;
    return <div className="flex flex-col items-center justify-center">
      <ProfileImage
        size="large"
        url={require(`../../content${profileImg}`)}/>
      <h1 className="text-2xl font-normal mt-10">{name}</h1>
      <p className="text-sm font-light mt-2">{designation}</p>
      {renderMdx(fields.shortDescMdx.childMdx.body)}
    </div>
  }

  return <Layout>
    <Section>
      <Grid withPageConstraints>
        <Grid.Col width={[12, 10, 8]} widthOffset={[0, 1, 2]}>
          <ContentBock>
            {renderMdx(contentMdx.childMdx.body)}
          </ContentBock>

          <div className="flex flex-wrap mt-20 justify-center">
            {membersList
              .map(({ name, designation, profileImg }, index) => {
                return <UserProfileCard
                  key={index}
                  onClick={() => showProfileDetails({ index })}
                  profileImg={{ url: require(`../../content${profileImg}`) }}
                  className="mr-4 mb-10">
                  <h1 className="text-xl text-center font-normal">{name}</h1>
                  <p className="text-sm text-center font-light">{designation}</p>
                </UserProfileCard>
              })
            }
          </div>

        </Grid.Col>
      </Grid>
    </Section>

    <Modal isOpen={profileDetails}
           onRequestClose={() => showProfileDetails(false)}
           parentSelector={() => document.getElementById('modal-root')}>
      {profileDetails && renderMemberProfilePreview(membersList[profileDetails.index])}
    </Modal>
  </Layout>
}

export default connect()(Home)

export const query = graphql`
  query {
    allContentYaml(filter: {tag: {in: "members-page"}}) {
      nodes {
        members_page_members_list
        fields {
          contentMdx {
            childMdx {
              body
            }
          }
        }
      }
    }
    allContentMember {
      nodes {
        designation
        name
        profileImg
        shortDesc
        title
        fields {
          shortDescMdx {
            childMdx {
              body
            }
          }
        }
      }
    }
  }
`
